import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
const LeadershipAcademy = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      leadership: file(relativePath: { eq: "leadership.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-147" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.leadership.childImageSharp.fluid}
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-147" })}</div>
                </div>

                <h6>{intl.formatMessage({ id: "innerpage-148" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-149" })}</p>



                <p>{intl.formatMessage({ id: "innerpage-150" })}</p>
                
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default LeadershipAcademy;
